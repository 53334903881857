import { useState } from "react";
import { Box, CircularProgress, Stack, Typography, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSearches, useActors } from "@/services/hooks";
import { fromNow } from "@/utils/time";

export default function SearchBox() {
  const [value, setValue] = useState(null);
  const { data: searches, isLoading, isError } = useSearches();
  const { data: actors } = useActors();

  // actors is a list [{key: "key", name: "name"}, ...]
  const actorMap = actors?.reduce((acc, actor) => {
    acc[actor.key] = actor;
    return acc;
  }, {});

  const getNameFromKey = (key) => {
    if (!actorMap) return "";
    return actorMap[key]?.name;
  };

  const handleSelect = (event, newValue) => {
    if (newValue) {
      window.location.href = `/search?searchKey=${newValue.uid}&currentView=land`;
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        Error loading searches. Please try again later.
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      <Autocomplete
        value={value}
        onChange={handleSelect} // Navigate when an option is selected
        options={searches || []}
        sx={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Find Engagement"
            variant="outlined"
            sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Result search={option} getNameFromKey={getNameFromKey} />
          </Box>
        )}
      />
    </Box>
  );
}

function Result({ search, getNameFromKey }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Stack direction="column" spacing={0.5}>
        <Typography variant="body1" sx={{ fontSize: "0.9rem" }}>
          {search.label}
        </Typography>

        <Typography variant="caption" color="text.secondary">
          {getNameFromKey(search.research_key)}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={0.5} alignItems="center">
        <Chip
          label={search.status}
          size="small"
          color="info"
          variant="outlined"
        />
        <Typography variant="caption" color="text.secondary">
          {fromNow(search.updated)}
        </Typography>
      </Stack>
    </Stack>
  );
}
