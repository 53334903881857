import { JoinInner } from "@mui/icons-material";
import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

import StrategyContainer from "./StrategyContainer";

export default function NotesDrawer() {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      {/* Button to open the drawer */}
      <Tooltip title="Strategy" placement="left">
        <IconButton color="info" onClick={() => setOpen(true)}>
          <JoinInner color="auto" />
        </IconButton>
      </Tooltip>

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <StrategyContainer open={open} setOpen={setOpen} />
      </Drawer>
    </Box>
  );
}
