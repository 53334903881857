import { API_BASE } from "@/App";

export const postSearch = async ({ uid, label, searchType, domain, notes }) => {
  try {
    const response = await fetch(`${API_BASE}/search`, {
      method: "POST",
      body: JSON.stringify({
        uid,
        label,
        type: searchType,
        domain,
        notes,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error posting search:", error);
    throw error;
  }
};

export const fetchTargets = async ({ searchUid, stage }) => {
  // console.log("fetchTargets", searchUid, stage);
  try {
    const response = await fetch(
      `${API_BASE}/targets?search_uid=${searchUid}&stage=${stage}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return data.map((target) => {
      // First spread meta to get base properties
      const unpacked = {
        ...(target.meta || {}),
        ...target, // Then spread target to override any duplicate properties
      };

      // Handle employees field conversion
      unpacked.employees = target.employees
        ? parseInt(target.employees, 10)
        : 0;

      return unpacked;
    });
  } catch (error) {
    console.error("Error fetching targets:", error);
    throw error;
  }
};

export const fetchTargetCount = async ({ searchUid }) => {
  const response = await fetch(
    `${API_BASE}/target_count?search_uid=${searchUid}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchSearchData = async ({ uid }) => {
  const response = await fetch(`${API_BASE}/search?search_uid=${uid}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchSearches = async () => {
  try {
    const endpoint = `${API_BASE}/searches`;
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("actorKey")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    // alert(error.message);
    console.error(error);
  }
};

export const postComment = async (targetId, comment) => {
  try {
    const endpoint = `${API_BASE}/comment`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        target_id: targetId,
        actor_key: localStorage.getItem("actorKey"),
        text: comment,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error posting comment:", error);
    throw error;
  }
};

export const deleteComment = async (commentId) => {
  try {
    const endpoint = `${API_BASE}/comment`;
    const response = await fetch(endpoint, {
      method: "DELETE",
      body: JSON.stringify({
        id: commentId,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};

export const updateTarget = async (searchUid, domain, data) => {
  console.log("updateTarget", searchUid, domain, data);
  try {
    const response = await fetch(`${API_BASE}/update_company`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: searchUid,
        domain,
        data,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating company:", error);
    throw error;
  }
};

// api.js
export const moveTargets = async (searchUid, domains, stage) => {
  console.log("moveTargets", searchUid, domains, stage);
  try {
    const response = await fetch(`${API_BASE}/move`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: searchUid,
        actor_key: localStorage.getItem("actorKey"),
        domains,
        stage,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error moving targets:", error);
    throw error;
  }
};

export const enrichTargets = async (searchUid, domains) => {
  try {
    const response = await fetch(`${API_BASE}/enrich_many`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: Number(searchUid),
        domains,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error enriching targets:", error);
    throw error;
  }
};

export const importTargets = async (searchUid, domains, stage, source) => {
  // console.log("importTargets", searchUid, domains, stage, source);
  try {
    const response = await fetch(`${API_BASE}/import`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: searchUid,
        domains,
        stage,
        source: Array.isArray(source) ? source.join(", ") : "",
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error importing targets:", error);
    throw error;
  }
};

export const fetchDomainStageMap = async (searchUid) => {
  const numericUid = Number(searchUid);
  const response = await fetch(`${API_BASE}/domain_name_stage/${numericUid}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchActors = async () => {
  const response = await fetch(`${API_BASE}/actors`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

// api.js
export const updateSearch = async (searchUid, data) => {
  try {
    const response = await fetch(`${API_BASE}/update_search`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: Number(searchUid),
        data,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating search:", error);
    throw error;
  }
};

export const generateCriteriaTargets = async (searchUid, criteria) => {
  try {
    const response = await fetch(`${API_BASE}/generate_criteria_targets`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: searchUid,
        criteria: criteria,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error generating criteria targets:", error);
    throw error;
  }
};

// api.js
export const generateSimilarTargets = async (target) => {
  try {
    const response = await fetch(`${API_BASE}/generate_similar_targets`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: target.search_uid,
        domain: target.domain,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error generating similar targets:", error);
    throw error;
  }
};

export const fetchContacts = async () => {
  try {
    const response = await fetch(`${API_BASE}/contacts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("actorKey")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching contacts:", error);
    throw error;
  }
};

export const getStageReview = async (searchUid, stage) => {
  // GET /stage_review?search_uid=1&stage=validate
  const response = await fetch(
    `${API_BASE}/stage_review?search_uid=${searchUid}&stage=${stage}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchLocations = async (prompt) => {
  const response = await fetch(`${API_BASE}/locations?prompt=${prompt}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const generatePlaceTargets = async (searchUid, q, locations, prompt) => {
  try {
    const response = await fetch(`${API_BASE}/generate_place_targets`, {
      method: "POST",
      body: JSON.stringify({
        q,
        locations,
        prompt,
        search_uid: searchUid,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error generating places:", error);
    throw error;
  }
};

export const fetchEvents = async (searchUid, type) => {
  const response = await fetch(
    `${API_BASE}/events?search_uid=${searchUid}&type=${type}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};
