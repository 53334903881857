// index.js
import { useSearches } from "@/services/hooks";
import { JoinInner } from "@mui/icons-material";
import { Box, Skeleton, Typography } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { getColumns } from "./columns";

export default function SearchesGrid() {
  const { data: searches, isLoading } = useSearches();

  const columns = getColumns();

  // Flatten meta fields onto each row
  const rows = searches?.map((search) => {
    const meta = search.meta || {};
    const {
      type,
      products,
      services,
      end_market,
      geographies,
      day,
      week,
      size,
      custom_columns,
      business_type,
      notes,
      stage,
    } = meta;
    return {
      ...search,
      type,
      products,
      services,
      end_market,
      geographies,
      day,
      week,
      size,
      custom_columns,
      business_type,
      notes,
      stage,
    };
  });

  if (isLoading) {
    return (
      <Box>
        <Skeleton variant="rectangular" height={340} sx={{ borderRadius: 1 }} />
      </Box>
    );
  }

  return (
    <Box sx={{ my: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <JoinInner sx={{ mr: 1 }} color="auto" />
        <Typography variant="h5">Active Search Strategies</Typography>
        <Typography variant="caption" sx={{ ml: 1, color: "gray" }}>
          Shared with GPT
        </Typography>
      </Box>

      {!searches?.length && (
        <Skeleton variant="rectangular" height={340} sx={{ borderRadius: 1 }} />
      )}

      {!!searches?.length && (
        <DataGridPremium
          rows={rows}
          columns={columns}
          getRowId={(r) => r.uid}
          density="compact"
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: { sortModel: [{ field: "created", sort: "desc" }] },
            // filter: {
            //   filterModel: {
            //     items: [{ field: "status", operator: "is", value: "active" }],
            //   },
            // },
            aggregation: { model: { updated: "max" } },
            columns: {
              columnVisibilityModel: {
                // status: false,
                day: false,
                week: false,
              },
            },
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50, 100]}
          disableRowSelectionOnClick
          cellSelection
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },
            "& .MuiDataGrid-footerCell": {
              color: "#04FFFF",
            },
          }}
        />
      )}
    </Box>
  );
}
