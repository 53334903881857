import { useSearchParams } from "react-router-dom";
import GoogleSearchDrawer from "@/components/GoogleSearchDrawer";
import GoogleMapsDrawer from "@/components/GoogleMapsDrawer";
import GrataDrawer from "@/components/GrataDrawer";
import ImportDrawer from "@/components/ImportDrawer";
import NotesDrawer from "@/components/NotesDrawer";
import TeamDrawer from "@/components/TeamDrawer";
import EventHistoryDrawer from "@/components/EventHistoryDrawer";
import { Box, Chip } from "@mui/material";
import { useSearchData } from "@/services/hooks";

export default function RightNav() {
  const [searchParams] = useSearchParams();
  const { data: searchData } = useSearchData({
    uid: searchParams.get("searchKey"),
  });

  return (
    <Box>
      <Box
        id="right-drawer-main"
        sx={{
          position: "fixed",
          backgroundColor: "rgba(19,19,19)",
          top: 70,
          right: 20,
          display: "flex",
          flexDirection: "column",
          // gap: 5,
          height: "90vh",
          width: 20,
          alignItems: "center",
          justifyContent: "space-between",
          py: 3,
        }}
      >
        {searchData && (
          <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <NotesDrawer />
              <GrataDrawer searchData={searchData} />

              <ImportDrawer searchData={searchData} />
              <GoogleSearchDrawer searchData={searchData} />
              <GoogleMapsDrawer searchData={searchData} />
            </Box>
            <Box>
              <EventHistoryDrawer />
              <TeamDrawer searchData={searchData} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
