import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddCircle, Clear } from "@mui/icons-material";
import {
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Typography,
  Box,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { postSearch } from "@/services/api";
import { SEARCH_TYPES } from "@/fragments/Constants";

const DOMAIN_OPTIONS = [
  "example.com",
  "acme.org",
  "anotherdomain.io",
  "q-centrix.com",
  "aptamigo.com",
  // Add more as needed
];

export default function CreateSearchContainer() {
  const [uid, setUid] = useState(null);
  // const [uid, setUid] = useState(Math.floor(Date.now() / 1000).toString());
  const [label, setLabel] = useState("");
  const [open, setOpen] = useState(false);
  const [searchType, setSearchType] = useState("Add-On");
  const [domain, setDomain] = useState("q-centrix.com");
  const [errorMsg, setErrorMsg] = useState("");
  const [notes, setNotes] = useState("");

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => postSearch({ uid, label, searchType, domain, notes }),
    onSuccess: () => {
      navigate(`/search?searchKey=${uid}&currentView=land`);
    },
    onError: (error) => {
      console.error(error);
      setErrorMsg(error.message || "Unknown error");
    },
  });

  const handleCreateSearch = () => {
    mutate();
  };

  const handleUidChange = (e) => {
    const input = e.target.value;
    if (input.includes("/")) {
      const uidFromUrl = input.split("/").pop();
      setUid(uidFromUrl);
    } else {
      setUid(input);
    }
  };

  useEffect(() => {
    // If searchType is "Fallout", use a timestamp as uid
    if (searchType === "Fallout") {
      setUid(Math.floor(Date.now() / 1000).toString());
    }
  }, [searchType]);

  return (
    <Box>
      {/* <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        size="small"
        sx={{ borderRadius: "30px" }}
        startIcon={<AddCircle />}
      >
        Add New Search
      </Button> */}

      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
            p: 3,
          },
        }}
      >
        <Container maxWidth="sm" sx={{ my: 4 }}>
          <IconButton onClick={() => setOpen(false)}>
            <Clear />
          </IconButton>
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Add New Search
          </Typography>

          {errorMsg && (
            <Alert
              severity="error"
              onClose={() => setErrorMsg("")}
              sx={{ mb: 2 }}
            >
              {errorMsg}
            </Alert>
          )}

          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <TextField
              select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              disabled={isLoading}
              helperText="Type of search"
              label="Type"
              variant="outlined"
              fullWidth
            >
              {SEARCH_TYPES.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            {/* Domain select field (replaces the old text field for Fallout) */}
            {/* <TextField
              select
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              disabled={isLoading}
              helperText="Select or type domain"
              label="Platform"
              variant="outlined"
              fullWidth
            >
              {DOMAIN_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField> */}

            <TextField
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              disabled={isLoading}
              helperText="Label for this search"
              label="Label"
              autoComplete="off"
              variant="outlined"
              fullWidth
              onKeyDown={(e) => {
                if (e.key === "Enter") handleCreateSearch();
              }}
            />

            {searchType !== "Fallout" && (
              <TextField
                value={uid}
                onChange={handleUidChange}
                disabled={isLoading}
                helperText="DealCloud ID or paste its URL"
                label="DealCloud Engagement URL"
                autoComplete="off"
                variant="outlined"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleCreateSearch();
                }}
              />
            )}

            {/* <TextField
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={isLoading}
              helperText="Any extra notes"
              label="Notes"
              multiline
              rows={3}
              variant="outlined"
              fullWidth
            /> */}

            <Button
              variant="contained"
              fullWidth
              onClick={handleCreateSearch}
              disabled={isLoading || !label || !searchType}
              sx={{ borderRadius: "30px" }}
            >
              Create Search
            </Button>
          </Box>
        </Container>
      </Drawer>
    </Box>
  );
}
