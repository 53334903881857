import EventHistoryContainer from "@/components/EventHistoryContainer";
import { Timeline } from "@mui/icons-material";
import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export default function EventHistoryDrawer() {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      {/* Button to open the drawer */}
      <Tooltip title="Event History" placement="left">
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Timeline color="auto" />
        </IconButton>
      </Tooltip>

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        {open && <EventHistoryContainer />}
      </Drawer>
    </Box>
  );
}
