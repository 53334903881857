import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Rating } from "@mui/material";
import { API_BASE } from "../App";

export default function RatingContainer({ target }) {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get("currentView");
  const [rating, setRating] = useState(target.rating);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (newValue) =>
      axios.post(API_BASE + "/rate_target", {
        search_uid: target.search_uid,
        domain: target.domain,
        type: "rating",
        actor_key: localStorage.getItem("actorKey"),
        data: {
          rating: newValue,
          currentView: currentView,
        },
      }),
    onSuccess: async () => {
      // Wait for 4 seconds to allow server-side processing
      await new Promise((r) => setTimeout(r, 200));

      // Invalidate relevant queries
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["targets", target.search_uid, currentView],
        }),
        queryClient.invalidateQueries({
          queryKey: ["targetCount", target.search_uid],
        }),
      ]);
    },
    onError: (error) => {
      console.error("Error updating rating:", error);
      // Optionally revert the local rating state on error
      setRating(target.rating);
    },
  });

  const handleRating = (e, newValue) => {
    if (newValue === null) {
      return;
    }

    // Update local state immediately for optimistic UI
    setRating(newValue);

    // Trigger the mutation
    mutation.mutate(newValue);
  };

  return (
    <Rating
      value={rating}
      onChange={handleRating}
      disabled={mutation.isPending}
    />
  );
}
