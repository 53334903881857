import { useContacts } from "@/services/hooks";
import { Box } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

export default function Contacts() {
  const { data: contacts, isLoading } = useContacts();

  const columns = [
    // { field: "id", flex: 1 },
    { field: "company", flex: 1 },
    { field: "name", flex: 1 },
    { field: "address", flex: 1 },
    { field: "city", flex: 1 },
    { field: "state", flex: 1 },
    { field: "postal_code", flex: 1 },
    // { field: "contact_phone", flex: 1 },
    // { field: "contact_mobile", flex: 1 },
    // { field: "contact_email", flex: 1 },
    // { field: "domain", flex: 1 },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box style={{ height: "80vh", width: "100%" }}>
      {/* set id as domain */}
      <DataGridPremium
        // getRowId={(row) => row.id}
        rows={contacts}
        columns={columns}
        pageSize={20}
        slots={{ toolbar: GridToolbar }}
      />
    </Box>
  );
}
