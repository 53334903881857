import React, { useState } from "react";
import { Hub } from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generateSimilarTargets } from "@/services/api";

export default function GetSimilarButton({ target }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const searchUid = target.search_uid;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    () => generateSimilarTargets(target),
    {
      onSuccess: (data) => {
        setSnackbarMsg(data.message || "Similar companies generated");
        setSnackbarOpen(true);

        queryClient.invalidateQueries(["targets", Number(searchUid)]);
        queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
        queryClient.invalidateQueries(["domainStageMap", searchUid]);
      },
      onError: (err) => console.error(err),
    }
  );

  return (
    <>
      <Tooltip title="Get Similar Companies" placement="top">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton onClick={() => mutate()} disabled={isLoading}>
            <Hub color="primary" />
          </IconButton>
        )}
      </Tooltip>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          color="primary"
          variant="filled"
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
