import { SpeakerNotes, TableView } from "@mui/icons-material";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function DisplayToggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const displayMode = searchParams.get("displayMode") || "card";
  // card or grid

  const handleToggle = () => {
    const newParams = new URLSearchParams(searchParams);
    if (displayMode === "card") {
      newParams.set("displayMode", "grid");
      setSearchParams(newParams);
    } else {
      newParams.set("displayMode", "card");
      setSearchParams(newParams);
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="caption" color="textSecondary">
        Display Mode
      </Typography>
      <ToggleButtonGroup
        value={displayMode}
        exclusive
        onChange={handleToggle}
        size="small"
        aria-label="display mode"
      >
        <ToggleButton value="grid" aria-label="grid view">
          <TableView color={displayMode === "grid" ? "info" : "inherit"} />
        </ToggleButton>
        <ToggleButton value="card" aria-label="list view">
          <SpeakerNotes color={displayMode === "card" ? "info" : "inherit"} />
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="caption" color="info">
        {displayMode === "grid" ? "Grid" : "Card"} View
      </Typography>
    </Stack>
  );
}
