import DisplayToggle from "@/components/DisplayToggle";
import SearchDomains from "@/components/SearchDomains";
import { DEALCLOUD_ENGAGEMENT_BASE, LABEL_MAP } from "@/fragments/Constants";
import { useSearchData } from "@/services/hooks";
import { getIcon } from "@/utils";
import { ArrowBackIos, IosShare, Launch } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NextResultsButton from "./NextResultsButton";
import logo from "@/images/logo.svg";

export function ShareButton() {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);

  const handleShare = () => {
    // Copy URL + params to clipboard
    const url = window.location.origin + "/search?" + searchParams.toString();
    navigator.clipboard.writeText(url);
    setOpen(true);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Tooltip title="Share current view" placement="top">
        <Button
          onClick={handleShare}
          color="primary"
          variant="text"
          startIcon={<IosShare />}
        >
          Share
        </Button>
      </Tooltip>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpen(false)} color="primary" variant="filled">
          Copied to clipboard!
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export function OpenDealcloud({ searchUid }) {
  const openInDealcloud = () => {
    const url = DEALCLOUD_ENGAGEMENT_BASE + searchUid;
    window.open(url, "_blank");
  };

  return (
    <Box>
      <Tooltip title="Open Dealcloud" placement="top">
        <IconButton onClick={openInDealcloud}>
          <Launch sx={{ fontSize: "20px", mx: 1, opacity: 0.7 }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default function PageAppBar() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchKey = searchParams.get("searchKey");
  const currentView = searchParams.get("currentView");

  const { data: searchData } = useSearchData({ uid: searchKey });

  return (
    <AppBar variant="transparent" position="sticky">
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          pl: 0,
          mr: 2,
          pt: 2,
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img
            src={logo}
            style={{ width: "240px", cursor: "pointer" }}
            onClick={() => (window.location.href = "/")}
          />
          <IconButton onClick={() => navigate("/")} aria-label="back to search">
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {searchData?.label}
          </Typography>
        </Box>

        <DisplayToggle />

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            display: {
              md: "none",
              lg: "flex",
            },
          }}
        >
          <SearchDomains searchUid={searchKey} />
          <NextResultsButton />
        </Stack>
        <OpenDealcloud searchUid={searchKey} />
        <ShareButton searchUid={searchKey} />
      </Toolbar>
    </AppBar>
  );
}
