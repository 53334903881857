import { getStageReview } from "@/services/api";
import { AutoAwesome, Favorite, Warning } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

export default function StageReviewContainer({ currentView, searchData }) {
  const [summary, setSummary] = useState(null);
  const [consider, setConsider] = useState(null);
  const [favorite, setFavorite] = useState(false);

  const fetchReview = async () => {
    try {
      const response = await getStageReview(searchData.uid, currentView);
      setSummary(response.summary);
      setConsider(response.consider);
      setFavorite(response.favorite);
    } catch (error) {
      console.error("Error fetching review:", error);
    }
  };

  useEffect(() => {
    if (!searchData) {
      return;
    }
    fetchReview();
  }, [currentView, searchData]);

  return (
    <Box sx={{ mt: 2 }}>
      <Stack direction="row" spacing={2}>
        <AutoAwesome color="auto" sx={{ fontSize: "20px" }} />
        <Typography>{summary}</Typography>
      </Stack>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Favorite color="primary" sx={{ fontSize: "20px" }} />
        {/* <Typography>{favorite}</Typography> */}
        <Box style={{ marginTop: "-20px" }}>
          <ReactMarkdown>{favorite}</ReactMarkdown>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Warning color="warning" sx={{ fontSize: "20px" }} />
        {/* <Typography>{consider}</Typography> */}
        <Box style={{ marginTop: "-20px" }}>
          <ReactMarkdown>{consider}</ReactMarkdown>
        </Box>
      </Stack>
    </Box>
  );
}
