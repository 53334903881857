import { useTargetMutation } from "@/services/mutations";
import { Clear } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useSearchParams } from "react-router-dom";
import { getColumns } from "./columns";
import { useMemo, useState } from "react";
import MoveTargets from "./MoveTargets";
import EnrichTargets from "./EnrichTargets";
import { LABEL_MAP } from "@/fragments/Constants";

function getSortModel(searchParams) {
  const sortParam = searchParams.get("sort") || "";
  if (!sortParam) return [{ field: "updated", sort: "desc" }];

  return sortParam.split(",").map((pair) => {
    const [field, direction] = pair.split(":");
    return { field, sort: direction };
  });
}

function getColumnVisibilityModelFromUrl(columns, searchParams) {
  const colsParam = searchParams.get("columns");
  const currentView = searchParams.get("currentView");

  let hidden;
  if (currentView === "client_approve") {
    hidden = [];
  } else {
    hidden = [
      "client_comments",
      "updated_by",
      "change_date",
      "contact_address",
      "postal_code",
      "country",
      "contact_phone",
      "contact_mobile",
      "designation",
      "contact_title",
      "contact_name",
      "contact_first_name",
      "contact_last_name",
      "contact_email",
      "revenue_estimates",
      "location_count",
      "facility_size",
    ];
  }

  if (!colsParam) {
    return columns.reduce((acc, col) => {
      acc[col.field] = !hidden.includes(col.field);
      return acc;
    }, {});
  }

  const visible = colsParam.split(",");
  return columns.reduce((acc, col) => {
    acc[col.field] = visible.includes(col.field);
    return acc;
  }, {});
}

function getColumnOrderFromUrl(searchParams, baseColumns) {
  const orderParam = searchParams.get("columns");
  if (!orderParam) return baseColumns.map((c) => c.field);
  return orderParam.split(",");
}

export default function TargetGridContainer({ targets, searchData }) {
  const apiRef = useGridApiRef();
  const mutation = useTargetMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectionModel, setSelectionModel] = useState([]);
  const [columnWidths, setColumnWidths] = useState(() => {
    const widths = searchParams.get("columnWidths");
    if (!widths) return {};
    return JSON.parse(widths);
  });
  const baseColumns = getColumns(searchData);

  // Column Order Model

  const [columnOrder, setColumnOrder] = useState(() =>
    getColumnOrderFromUrl(searchParams, baseColumns)
  );

  const orderedColumns = useMemo(() => {
    const baseMap = new Map(baseColumns.map((col) => [col.field, col]));
    const reordered = columnOrder
      .map((field) => baseMap.get(field))
      .filter(Boolean);
    // any columns not in order go at the end
    const leftovers = baseColumns.filter((c) => !columnOrder.includes(c.field));
    return [...reordered, ...leftovers];
  }, [baseColumns, columnOrder]);

  const handleColumnOrderChange = () => {
    const visibleColumns = apiRef.current
      .getVisibleColumns()
      .map((col) => col.field);
    console.log("Visible columns", visibleColumns);
    setColumnOrder(visibleColumns);

    // Update URL
    const newParams = new URLSearchParams(searchParams);
    newParams.set("columns", visibleColumns.join(","));
    setSearchParams(newParams);
  };

  // Column Visibility Model
  // starts from all columns, then filtered by columns in URL
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() =>
    getColumnVisibilityModelFromUrl(baseColumns, searchParams)
  );

  const handleColumnVisibilityModelChange = (newModel) => {
    console.log("New column visibility model");
    setColumnVisibilityModel(newModel);
    const visibleFields = Object.entries(newModel)
      .filter(([_, isVisible]) => isVisible)
      .map(([field]) => field);

    const newParams = new URLSearchParams(searchParams);
    if (visibleFields.length && visibleFields.length < baseColumns.length) {
      newParams.set("columns", visibleFields.join(","));
    } else {
      newParams.delete("columns");
    }
    setSearchParams(newParams);
  };

  // Sort Model
  // starts from the URL, then mapped to the DataGrid API
  const sortModel = getSortModel(searchParams);
  const handleSortModelChange = (newModel) => {
    // Convert array to something like "field:asc,domain:desc"
    const newSortParam = newModel
      .map((item) => `${item.field}:${item.sort}`)
      .join(",");

    const newParams = new URLSearchParams(searchParams);
    if (newSortParam) {
      newParams.set("sort", newSortParam);
    } else {
      newParams.delete("sort");
    }
    setSearchParams(newParams);
  };

  // Filter Model

  const [filterModel, setFilterModel] = useState(() => {
    const filterParam = searchParams.get("filter");
    if (!filterParam) return { items: [] };
    return JSON.parse(filterParam);
  });

  const handleFilterModelChange = (newModel) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("filter", JSON.stringify(newModel));
    setSearchParams(newParams);

    setFilterModel(newModel);
  };

  // Column Resize
  const handleColumnResize = (newColumnSizes) => {
    console.log("New column sizes", newColumnSizes);
    const updatedWidths = columnWidths;
    updatedWidths[newColumnSizes.colDef.field] = newColumnSizes.width;
    setColumnWidths(updatedWidths);
    console.log("Updated column widths", updatedWidths);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("columnWidths", JSON.stringify(updatedWidths));
    setSearchParams(newParams);
  };

  // then merge with ordered columns to create final columns

  const finalColumns = useMemo(() => {
    return orderedColumns.map((col) => {
      const width = columnWidths[col.field] ?? col.width;
      return { ...col, width, hide: !columnVisibilityModel[col.field] };
    });
  }, [orderedColumns, columnWidths, columnVisibilityModel]);

  const handleUpdate = async (newRow, oldRow) => {
    const changes = Object.entries(newRow).reduce((acc, [key, value]) => {
      if (oldRow[key] !== value) acc[key] = value;
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) return oldRow;

    try {
      mutation.mutateAsync({
        searchUid: searchData.uid,
        stage: oldRow.stage,
        domain: newRow.domain,
        data: changes,
      });
      return newRow;
    } catch (error) {
      console.error("Error updating row:", error);
      return oldRow;
    }
  };

  const getFileName = () => {
    const label = searchData?.label;
    const stage = LABEL_MAP[searchParams.get("currentView")];
    const today = new Date().toISOString().split("T")[0];
    return `${today}_${stage}_${label}`;
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 2,
        mr: "100px",
        "& .MuiDataGrid-root": { borderColor: "rgba(19,19,19)" },
      }}
    >
      {selectionModel.length > 0 && (
        <Stack direction="row" spacing={2} justifyContent={"space-between"}>
          <MoveTargets apiRef={apiRef} />
          <EnrichTargets apiRef={apiRef} />
        </Stack>
      )}
      <Box sx={{ height: "calc(100vh - 140px)", width: "calc(100vw - 220px)" }}>
        <DataGridPremium
          apiRef={apiRef}
          rows={targets}
          getRowId={(row) => row.domain}
          columns={finalColumns}
          onColumnOrderChange={handleColumnOrderChange}
          density="compact"
          processRowUpdate={handleUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            toolbar: {
              fileName: getFileName(),
            },
          }}
          pagination
          pageSizeOptions={[10, 20, 50, 100]}
          checkboxSelection
          selectionModel={selectionModel}
          onRowSelectionModelChange={(newSelection) =>
            setSelectionModel(newSelection)
          }
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onColumnResize={handleColumnResize}
          disableRowSelectionOnClick
          cellSelection
        />
      </Box>
    </Box>
  );
}

function CustomToolbar({ fileName }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleReset = () => {
    // keep only the searchKey, currentView, and displayMode
    const newParams = new URLSearchParams();
    newParams.set("searchKey", searchParams.get("searchKey"));
    newParams.set("currentView", searchParams.get("currentView"));
    newParams.set("displayMode", searchParams.get("displayMode"));
    setSearchParams(newParams);
    window.location.reload();
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
      <Stack direction="row" spacing={1}>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </Stack>

      <Stack direction="row" spacing={1}>
        <GridToolbarExport
          csvOptions={{ fileName: fileName }}
          excelOptions={{ fileName: fileName }}
        />
        <Button size="small" startIcon={<Clear />} onClick={handleReset}>
          Reset
        </Button>
      </Stack>
    </Stack>
  );
}
