import { Groups } from "@mui/icons-material";
import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import TeamContainer from "./TeamContainer";

export default function TeamDrawer({ searchData }) {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      {/* Button to open the drawer */}
      <Tooltip title="Team" placement="left">
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Groups color="primary" />
        </IconButton>
      </Tooltip>

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        {searchData && (
          <TeamContainer setOpen={setOpen} searchData={searchData} />
        )}
      </Drawer>
    </Box>
  );
}
