import { LABEL_MAP } from "@/fragments/Constants";
import { getIcon } from "@/utils";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useTargetCount } from "@/services/hooks";
import { useSearchParams } from "react-router-dom";

export default function LeftNav() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchUid = searchParams.get("searchKey");
  const currentView = searchParams.get("currentView");

  const setView = (name) => {
    // get the current params, update the currentView param, and set the new params
    const params = new URLSearchParams(searchParams);
    params.set("currentView", name);
    setSearchParams(params);
  };

  const getColor = (key) => (currentView === key ? "info" : "inherit");

  const { data: targetCount, isLoading } = useTargetCount({
    searchUid: searchUid,
  });

  if (isLoading) {
    return null;
  }

  return (
    <Box
      id="left-drawer-main"
      sx={{
        position: "fixed",
        backgroundColor: "rgba(19,19,19)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100vh",
        width: 150,
        py: 6,
        zIndex: 1000,
      }}
    >
      <List>
        {Object.keys(LABEL_MAP).map((key) => (
          <ListItem key={key}>
            <Tooltip title={LABEL_MAP[key]} placement="right-start">
              <ListItemButton
                onClick={() => setView(key)}
                selected={currentView === key}
                sx={{ py: 0.5 }}
              >
                <ListItemIcon>{getIcon(key, getColor(key))}</ListItemIcon>
                <ListItemText primary={targetCount[key]} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
