import { enrichTargets } from "@/services/api";
import { AutoAwesome } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function EnrichTargets({ apiRef }) {
  const [searchParams] = useSearchParams();
  const searchUid = searchParams.get("searchKey");
  const currentView = searchParams.get("currentView");

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Get selected domains
  const [domains, setDomains] = useState([]);

  // Track if there are pending changes
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Handler for selection changes
  const handleSelectionChange = () => {
    if (!apiRef.current) return;

    const selectedRows = apiRef.current.getSelectedRows();
    const domainArray = Array.from(selectedRows.keys());
    setDomains(domainArray);
    setIsButtonDisabled(domainArray.length === 0);
  };

  // Initialize selected domains
  useEffect(() => {
    if (apiRef.current) {
      handleSelectionChange();
    }
  }, [apiRef]);

  // Add selection model change listener
  useEffect(() => {
    if (!apiRef.current) return;

    // Subscribe to selection changes
    const unsubscribe = apiRef.current.subscribeEvent(
      "rowSelectionChange",
      handleSelectionChange
    );

    // Cleanup subscription on unmount
    return () => {
      try {
        if (apiRef.current) {
          unsubscribe(); // Properly unsubscribe using the returned function
        }
      } catch (e) {
        console.warn("Failed to unsubscribe from selection events:", e);
      }
    };
  }, [apiRef]);

  const queryClient = useQueryClient();

  // Setup mutation
  const { mutate, isLoading, error, isSuccess } = useMutation({
    mutationFn: () => enrichTargets(searchUid, domains),
    onSuccess: () => {
      // add a 3 second delay to allow for enrichment to complete
      setSnackbarMsg(
        `Started enriching ${domains.length} target${
          domains.length > 1 ? "s" : ""
        }`
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        // unselect rows after enrichment
        if (apiRef.current) {
          apiRef.current.setRowSelectionModel([]);
        }

        // Invalidate and refetch targets to show updated data
        queryClient.invalidateQueries([
          "targets",
          Number(searchUid),
          currentView,
        ]);

        // Show success snackbar
      }, 3000);
    },
    onError: (error) => {
      // Show error snackbar
      setSnackbarMsg(getErrorMessage(error));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    },
  });

  const handleEnrichSelectedTargets = () => {
    if (domains.length > 0) {
      mutate();
    }
  };

  // Format error message for display
  const getErrorMessage = (error) => {
    if (!error) return "An error occurred while enriching targets";

    if (error.response?.data?.message) {
      return error.response.data.message;
    }

    return error.message || "Failed to enrich targets. Please try again.";
  };

  // Handle snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, my: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Tooltip
          title={
            domains.length === 0
              ? "Select targets to enrich"
              : `Enrich ${domains.length} selected target${
                  domains.length > 1 ? "s" : ""
                } with additional data`
          }
        >
          <span>
            <Button
              variant="outlined"
              color="auto"
              onClick={handleEnrichSelectedTargets}
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <AutoAwesome />
              }
              disabled={isLoading || isButtonDisabled}
            >
              Enrich Selected ({domains.length})
            </Button>
          </span>
        </Tooltip>
      </Box>

      {/* Top center snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
