// mutations.js
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateTarget, updateSearch } from "@/services/api";

export const useTargetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ searchUid, stage, domain, data }) =>
      updateTarget(searchUid, domain, data),

    onMutate: async ({ searchUid, stage, data }) => {
      // Cancel queries with the same searchUid and stage
      await queryClient.cancelQueries({
        queryKey: ["targets", Number(searchUid), stage],
      });

      const previousData = queryClient.getQueryData([
        "targets",
        Number(searchUid),
        stage,
      ]);

      // Update the cache
      queryClient.setQueryData(["targets", Number(searchUid), stage], (old) =>
        old?.map((target) =>
          target.domain === data.domain ? { ...target, ...data } : target
        )
      );

      return { previousData };
    },

    onError: (err, { searchUid, stage }, context) => {
      // Revert the optimistic update
      queryClient.setQueryData(
        ["targets", Number(searchUid), stage],
        context.previousData
      );
    },

    onSuccess: (_, { searchUid, stage }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["targets", Number(searchUid), stage],
      });
    },
  });
};
