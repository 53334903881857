import ReactQueryProvider from "@/providers/ReactQuery";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login";
import Reporting from "./pages/Reporting";
import SearchResults from "./pages/SearchResults";
import Contacts from "./pages/Contacts";
import { getApiBase, getHelmetTitle } from "./utils";
import HelmetTitle from "@/components/HelmetTitle";

LicenseInfo.setLicenseKey(
  "d6170662ba5642dff2dfd6ab7ddc75f9Tz0xMDI1NDYsRT0xNzY0MTY3MDkzMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

const clientId =
  "471025930138-cgahb4fj4p5tignjog5k62kp96ivcbti.apps.googleusercontent.com";
export const API_BASE = getApiBase();
console.log("App API_BASE", API_BASE);

export default function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkToken = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (
      !accessToken ||
      !tokenExpiration ||
      new Date(tokenExpiration) < new Date()
    ) {
      handleLogout();
      return;
    } else {
      setIsAuthorized(true);
    }
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("actorKey");
    localStorage.removeItem("actorName");
    localStorage.removeItem("tokenExpiration");
    setIsAuthorized(false);
    setLoading(false);
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (loading) return null;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ReactQueryProvider>
        <HelmetTitle />
        <Router>
          <Routes>
            <Route
              path="/login"
              element={!isAuthorized ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="/"
              element={isAuthorized ? <Reporting /> : <Navigate to="/login" />}
            />
            <Route
              path="/search"
              element={
                isAuthorized ? <SearchResults /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/contacts"
              element={isAuthorized ? <Contacts /> : <Navigate to="/login" />}
            />
          </Routes>
        </Router>
      </ReactQueryProvider>
    </GoogleOAuthProvider>
  );
}
