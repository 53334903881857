import {
  AccountTree,
  Business,
  CurrencyExchange,
  Home,
  Map,
  People,
  Scale,
} from "@mui/icons-material";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ChipRow({ searchData, target }) {
  if (!target || !searchData) return null;

  const renderChips = (items, key, icon, colorCheck = null) =>
    items
      ?.split(",")
      .map((item, index) => (
        <Chip
          key={`${key}-${index}`}
          icon={icon}
          label={item.trim()}
          variant="outlined"
          color={colorCheck?.includes(item.trim()) ? "auto" : "default"}
          style={{ margin: "5px" }}
        />
      ));

  return (
    <Box
      id="chips"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" flexWrap={"wrap"}>
        {target.business_type && (
          <Chip
            icon={<Business />}
            label={target.business_type}
            variant="outlined"
            color="auto"
            style={{ margin: "5px" }}
          />
        )}
        {target.industry && (
          <Chip
            icon={<Business />}
            label={target.industry}
            variant="outlined"
            color="auto"
            style={{ margin: "5px" }}
          />
        )}
        {target.source && (
          <Chip
            icon={<AccountTree />}
            color="info"
            label={target.source}
            variant="outlined"
            style={{ margin: "5px" }}
          />
        )}

        {target.meta?.headquarters && (
          <Chip
            icon={<Home />}
            label={target.meta.headquarters}
            variant="outlined"
            style={{ margin: "5px" }}
          />
        )}
        {renderChips(
          target.geographies,
          "geographies",
          <Map />,
          searchData.meta?.geographies
        )}
        {target.ownership && (
          <Chip
            icon={<Business />}
            label={target.ownership}
            variant="outlined"
            style={{ margin: "5px" }}
          />
        )}
      </Stack>

      {/* size row */}

      {/* <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption">size</Typography>
        <Chip
          icon={<People />}
          label={parseInt(target.employees)}
          variant="outlined"
          style={{ margin: "5px", minWidth: "80px" }}
        />

        {target.revenue_estimates && (
          <Chip
            icon={<CurrencyExchange />}
            label={`$${(target.revenue_estimates / 1000000).toFixed(1)}M`}
            variant="outlined"
          />
        )}
      </Stack> */}

      {["products", "services", "end_market"].map((key) =>
        target[key]?.trim() ? (
          <Stack
            key={key}
            direction="row"
            spacing={1}
            alignItems="center"
            flexWrap={"wrap"}
          >
            <Typography variant="caption">{key.replace("_", " ")}</Typography>
            {renderChips(
              target[key],
              key,
              null, // Replace with a suitable icon if needed
              searchData.meta?.[key]
            )}
          </Stack>
        ) : null
      )}

      {/* New stack for '_searches' array */}
      {target._searches?.length > 0 && (
        <Stack
          direction="row"
          spacing={1}
          flexWrap="wrap"
          mt={1}
          alignItems="center"
        >
          <Typography variant="caption">searches</Typography>
          {target._searches.map((eng) => (
            <Chip
              key={eng.id}
              label={eng.name}
              variant="outlined"
              style={{ margin: "5px" }}
              onClick={() =>
                window.open(
                  `/search?searchKey=${eng.id}&currentView=create`,
                  "_blank"
                )
              }
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
