import { Close, Groups, Save } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { updateSearch } from "@/services/api";
import { useActors } from "@/services/hooks";
import { useSearchParams } from "react-router-dom";

export default function TeamContainer({ open, setOpen, searchData }) {
  const { data: actors } = useActors();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    lead_key: searchData?.lead_key || "",
    bd_lead_key: searchData?.bd_lead_key || "",
    bd_analyst_key: searchData?.bd_analyst_key || "",
    research_key: searchData?.research_key || "",
  });
  const [error, setError] = useState(null);

  // Pass an object with uid and data to updateSearch
  const mutation = useMutation(({ uid, data }) => updateSearch(uid, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["searchData"]);
      setOpen(false);
    },
    onError: (err) => setError(err.message || "An error occurred"),
  });

  const handleFieldChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  // Use searchData.uid as the search uid
  const handleSave = () => {
    mutation.mutate({ uid: searchData.uid, data: formData });
  };

  return (
    <Box>
      <Container maxWidth="xl" sx={{ maxHeight: "85vh", px: 4, pt: 4, pb: 10 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 8 }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
          <Stack direction="row" spacing={2} alignItems="center">
            <Groups color="primary" sx={{ fontSize: "40px" }} />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Team
            </Typography>
          </Stack>
          <Button
            variant="contained"
            startIcon={<Save />}
            onClick={handleSave}
            disabled={mutation.isLoading}
            sx={{ borderRadius: 30 }}
          >
            Save
          </Button>
        </Stack>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Stack direction="row" spacing={4} sx={{ my: 2 }}>
          {[
            { name: "lead_key", label: "Deal Lead" },
            { name: "bd_lead_key", label: "BD Lead" },
            { name: "bd_analyst_key", label: "BD Analyst" },
            { name: "research_key", label: "Research" },
          ].map((field) => (
            <TextField
              key={field.name}
              name={field.name}
              select
              label={field.label}
              value={formData[field.name]}
              onChange={handleFieldChange}
              fullWidth
            >
              {actors?.map((actor) => (
                <MenuItem key={actor.key} value={actor.key}>
                  {actor.name}
                </MenuItem>
              ))}
            </TextField>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
