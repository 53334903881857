import {
  AccountBalance,
  Block,
  CheckCircle,
  ErrorOutline,
  OutboundOutlined,
  Send,
  ThumbUp,
  Upload,
  Verified,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { API_BASE } from "@/App";
import { fromNow } from "@/utils/time";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function EventButtonsContainer({ target, enrichTarget }) {
  const [error, setError] = useState(null);
  const [conflictAnchorEl, setConflictAnchorEl] = useState(null);
  const targetStage = target["stage"];

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (type) =>
      axios.post(API_BASE + "/transition_event", {
        search_uid: target.search_uid,
        domain: target.domain,
        type,
        data: { id: target.id },
        actor_key: localStorage.getItem("actorKey"),
      }),
    onMutate: async (type) => {
      await queryClient.cancelQueries({
        queryKey: ["targets", target.search_uid, targetStage],
      });
      const previousTargets = queryClient.getQueryData([
        "targets",
        target.search_uid,
        targetStage,
      ]);
      queryClient.setQueryData(
        ["targets", target.search_uid, targetStage],
        (old) => old.filter((t) => t.id !== target.id)
      );
      return { previousTargets };
    },
    onError: (error, type, context) => {
      setError(error.message);
      if (context?.previousTargets) {
        queryClient.setQueryData(
          ["targets", target.search_uid, targetStage],
          context.previousTargets
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["targets", target.search_uid, targetStage],
      });
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["targetCount", target.search_uid],
        }),
        queryClient.invalidateQueries({
          queryKey: ["searchData", target.search_uid],
        }),
      ]),
  });

  const postEvent = (type) => {
    setConflictAnchorEl(null);
    mutation.mutate(type);
  };

  // conflict menu stuff

  return (
    <Box
      id="button-group-container"
      sx={{ display: "flex", alignItems: "center", gap: 4 }}
    >
      {error && <Warning color="error" />}

      <Typography variant="caption" sx={{ opacity: 0.5, mr: 4 }}>
        {fromNow(target["updated"])}
      </Typography>

      <span>
        <IconButton
          color="darkgray"
          onClick={(event) => setConflictAnchorEl(event.currentTarget)}
        >
          <ErrorOutline />
        </IconButton>
        <Menu
          id="conflict-menu"
          anchorEl={conflictAnchorEl}
          open={Boolean(conflictAnchorEl)}
          onClose={() => setConflictAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            color="info"
            onClick={(e) => postEvent("conflict")}
            style={{ textTransform: "uppercase" }}
          >
            Internal Conflict
          </MenuItem>
          <MenuItem
            color="info"
            onClick={(e) => postEvent("client_conflict")}
            style={{ textTransform: "uppercase" }}
          >
            Client Conflict
          </MenuItem>
        </Menu>
      </span>

      {targetStage != "buyer" && (
        <Tooltip title="Support the Fallout Strategy">
          <Button
            variant="text"
            startIcon={<AccountBalance />}
            onClick={(e) => postEvent("buyer")}
            color="darkgray"
          >
            Buyer
          </Button>
        </Tooltip>
      )}

      {targetStage != "reject" && (
        <Button
          variant="text"
          startIcon={<Block />}
          onClick={(e) => postEvent("reject")}
          color="error"
        >
          Reject
        </Button>
      )}

      {targetStage == "send" && (
        <Button
          variant="contained"
          startIcon={<Block />}
          onClick={(e) => postEvent("client_reject")}
          color="warning"
        >
          Client Reject
        </Button>
      )}

      {["create", "land", "similar"].includes(targetStage) && (
        <Button
          variant="contained"
          endIcon={<ThumbUp />}
          onClick={(e) => postEvent("advance")}
          color="primary"
        >
          Review
        </Button>
      )}

      {targetStage == "advance" && (
        <Tooltip
          title={
            target["rating"] == null
              ? "Rating required to validate"
              : "Research Validated"
          }
        >
          <span>
            <Button
              variant="contained"
              endIcon={<Verified />}
              onClick={(e) => postEvent("validate")}
              color="primary"
            >
              Validate
            </Button>
          </span>
        </Tooltip>
      )}

      {targetStage == "validate" && (
        <Button
          variant="outlined"
          endIcon={<OutboundOutlined />}
          onClick={(e) => postEvent("outbox")}
          color="auto"
        >
          Ready to Send
        </Button>
      )}

      {targetStage == "outbox" && (
        <Button
          variant="contained"
          endIcon={<Send />}
          onClick={(e) => postEvent("send")}
          color="secondary"
        >
          Send
        </Button>
      )}

      {targetStage == "send" && (
        <Button
          variant="contained"
          endIcon={<CheckCircle />}
          onClick={(e) => postEvent("client_approve")}
          color="primary"
        >
          Client Approved
        </Button>
      )}
      {targetStage == "client_approve" && (
        <Button
          variant="contained"
          endIcon={<Upload />}
          onClick={(e) => postEvent("sync")}
          color="success"
        >
          Sync
        </Button>
      )}
    </Box>
  );
}
