import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";
import { moveTargets } from "@/services/api";
import { MoveDown } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function MoveTargets({ apiRef }) {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get("currentView");
  const searchUid = searchParams.get("searchKey");
  const [moveTo, setMoveTo] = useState(currentView);
  const queryClient = useQueryClient();

  // Get selected domains
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    if (apiRef.current) {
      const selectedRows = apiRef.current.getSelectedRows();
      const domainArray = Array.from(selectedRows.keys());
      setDomains(domainArray);
    }
  }, [apiRef]);

  // Add selection model change listener
  useEffect(() => {
    if (apiRef.current) {
      const handleSelectionChange = () => {
        const selectedRows = apiRef.current.getSelectedRows();
        const domainArray = Array.from(selectedRows.keys());
        setDomains(domainArray);
      };

      // Subscribe to selection changes
      apiRef.current.subscribeEvent(
        "rowSelectionChange",
        handleSelectionChange
      );
    }
  }, [apiRef]);

  // Setup mutation
  const { mutate, isLoading, error } = useMutation({
    mutationFn: () => moveTargets(searchUid, domains, moveTo),
    onSuccess: () => {
      // Clear selection after successful move
      if (apiRef.current) {
        apiRef.current.setRowSelectionModel([]);
      }
      // Invalidate and refetch relevant queries
      queryClient.invalidateQueries([
        "targets",
        Number(searchUid),
        currentView,
      ]);
      queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
    },
  });

  const handleMoveSelectedTargets = () => {
    if (domains.length > 0 && moveTo !== currentView) {
      mutate();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, my: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          select
          label="Move to"
          value={moveTo}
          onChange={(e) => setMoveTo(e.target.value)}
          style={{ minWidth: "120px" }}
          size="small"
          disabled={isLoading}
        >
          {Object.keys(LABEL_MAP).map((key) => (
            <MenuItem key={key} value={key} disabled={key === currentView}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                {ICON_MAP[key]}
                {LABEL_MAP[key]}
              </Box>
            </MenuItem>
          ))}
        </TextField>

        <Button
          variant="outlined"
          color="secondary"
          onClick={handleMoveSelectedTargets}
          startIcon={isLoading ? <CircularProgress size={20} /> : <MoveDown />}
          disabled={isLoading || moveTo === currentView || domains.length === 0}
        >
          Move Selected ({domains.length})
        </Button>
      </Box>

      {error && (
        <Alert severity="error">
          {error.message || "An error occurred while moving targets"}
        </Alert>
      )}
    </Box>
  );
}
