import ErrorAlert from "@/components/ErrorAlert";
import {
  KeyboardDoubleArrowRight,
  LocationOn,
  Cancel,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
} from "@mui/material";

import {
  fetchLocations,
  generatePlaceTargets,
  fetchEvents,
} from "@/services/api";
import { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";

export default function GoogleSearchDrawer({ searchData }) {
  const [open, setOpen] = useState(false);

  const [prompt, setPrompt] = useState("");
  const [locations, setLocations] = useState([]);
  const [q, setQ] = useState(null);

  const [events, setEvents] = useState([]);
  const [promptSuggestions, setPromptSuggestions] = useState([]);
  const [qSuggestions, setQSuggestions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const queryClient = useQueryClient();

  const handleGetEvents = async () => {
    try {
      const response = await fetchEvents(searchData.uid, "google_maps");
      setEvents(response);

      // Extract unique prompts and q values from past events to use as suggestions
      if (response && response.length > 0) {
        const uniquePrompts = [
          ...new Set(
            response.map((event) =>
              event.data && event.data.prompt ? event.data.prompt : null
            )
          ),
        ];
        setPromptSuggestions(uniquePrompts.filter(Boolean)); // Filter out null/undefined values

        const uniqueQs = [
          ...new Set(
            response.map((event) =>
              event.data && event.data.q ? event.data.q : null
            )
          ),
        ];
        setQSuggestions(uniqueQs.filter(Boolean)); // Filter out null/undefined values
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetEvents();
  }, []);

  const handleReset = () => {
    setPrompt("");
    setLocations([]);
    setQ(null);
    setLoading(false);
    setError(null);
  };

  const handleGetLocations = async () => {
    try {
      setLoading(true);
      const response = await fetchLocations(prompt);
      setLocations(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGeneratePlaceTargets = async () => {
    try {
      setLoading(true);
      const response = await generatePlaceTargets(
        searchData.uid,
        q,
        locations,
        prompt
      );
      queryClient.invalidateQueries({
        queryKey: ["targets", target.search_uid, "create"],
      });
      queryClient.invalidateQueries({
        queryKey: ["targetCount", target.search_uid],
      });
      // if (response) {
      //   setOpen(false);
      // }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (q) {
      await handleGeneratePlaceTargets();
    } else if (prompt) {
      await handleGetLocations();
    }
  };

  return (
    <Box>
      <ErrorAlert error={error} setError={setError} />
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Google Maps Search" placement="left">
          <IconButton color="primary" onClick={() => setOpen(!open)}>
            <LocationOn />
          </IconButton>
        </Tooltip>
      </Box>

      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ position: "absolute", left: 10, top: 10 }}
          color="info"
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
        <Box
          sx={{
            pt: 8,
            px: 8,
            width: "600px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 4 }}>
            Google Maps
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              height: "50px",
            }}
          >
            {prompt && locations.length > 0 && (
              <IconButton onClick={handleReset} color="darkgray">
                <Cancel />
              </IconButton>
            )}
          </Box>

          <Autocomplete
            freeSolo
            options={promptSuggestions}
            value={prompt}
            disabled={loading || locations.length > 0}
            onInputChange={(event, newValue) => {
              setPrompt(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Prompt describing where to search. e.g. 'Top 25 locations in Texas' 'Top 10 locations in sunbelt states'"
                // helperText="'"
                size="small"
                multiline
                maxRows={8}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
            )}
            fullWidth
          />

          <Box sx={{ my: 2, width: "100%" }}>
            {locations.map((location) => (
              <Chip
                label={location["label"]}
                variant="outlined"
                color="auto"
                size="small"
                key={location["label"]}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>

          {locations.length > 0 && (
            <Autocomplete
              freeSolo
              options={qSuggestions}
              value={q}
              onInputChange={(event, newValue) => {
                setQ(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Google Maps"
                  size="small"
                  autoFocus
                  multiline
                  maxRows={8}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                />
              )}
              fullWidth
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            sx={{ textTransform: "none", my: 2 }}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Go
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}
